import React, { useEffect } from "react"

export default function Ar({ location, ...props }) {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.location.href = "https://www.instagram.com/ar/385397779774689/"
    }
  }, [])

  return <div></div>
}
